// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /* global ajax_object */
  var cat_btn = $('.js-filter-item');

  cat_btn.each(function () {
    $(this).click(function () {
      cat_btn.each(function () {
        $(this).removeClass('is-active');
      });
      $(this).addClass('is-active');
    });
  });

  function postFilter(e) {
    e.preventDefault();
    let category = $(this).data('category');
    let container = $('.posts-list');
    let btnLoadMore = $('.load-more-btn');

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'filter',
        category: category,
      },
      type: 'post',
      success: function (result) {
        $('.posts-list').html(result.html);
        if (container.find('article').length >= result.post_count) {
          btnLoadMore.hide();
        } else {
          btnLoadMore.show();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  }

  $(document).on('click', '.js-filter-item > a', postFilter);

  function loadPost() {
    // jQuery code goes here
    let btnLoadMore = $(this);
    let categoryId = $('.categories-list .js-filter-item.is-active a').data(
      'category'
    );
    let offset = $('.posts-list').find('article').length;
    // console.log('Category ID: ' + categoryId);
    // console.log('Offset: ' + offset);
    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'load_more',
        category: categoryId,
        offset: offset,
        to_show: 9,
      },
      type: 'post',
      success: function (result) {
        $('.posts-list').append(result.html);
        $('.preview__text-wrap').matchHeight();
        if (offset >= result.post_count) {
          btnLoadMore.hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  }

  if ($('.posts-list').length) {
    $(document).on('click', '.load-more-btn', loadPost);
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.card__text-wrap').matchHeight();
  $('.tabs-panel__container').matchHeight();
  $('.preview__text-wrap').matchHeight();

  $('.cards-slider').on('init', function () {
    $('.cards-slider__item').matchHeight({ byRow: false });
    $('.cards-slider__text-wrap').matchHeight({ byRow: false });
  });

  $('.cards').on('init', function () {
    $('.cards__item').matchHeight({ byRow: false });
    // $('.cards__text-wrap').matchHeight({ byRow: false });
  });

  $('.text-slider').on('init', function () {
    $('.text-wrap').matchHeight();
    $('.text-slider__title').matchHeight();
  });

  $('.testimonials-slider').on('init', function () {
    $('.testimonial').matchHeight();
  });

  $('.text-slider').slick({
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: $('.text-slider__dots'),
    prevArrow: $('.text-slider__arrow-prev'),
    nextArrow: $('.text-slider__arrow-next'),
  });

  $('.testimonials-slider').slick({
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.testimonials-slider__arrow-prev'),
    nextArrow: $('.testimonials-slider__arrow-next'),
  });

  $('.cards-slider').slick({
    infinite: true,
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // mobileFirst: true,
    appendDots: $('.cards-slider__dots'),
    prevArrow: $('.cards-slider__arrow-prev'),
    nextArrow: $('.cards-slider__arrow-next'),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.cards:not(.cards--no-slider)').slick({
    infinite: false,
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('.cards__arrow-prev'),
    nextArrow: $('.cards__arrow-next'),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  let $partnerSlider = $('.partners-slider .partners-slider__container');

  if ($partnerSlider.length) {
    $partnerSlider.slick({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: $partnerSlider.data('slides'),
      slidesToScroll: 1,
      prevArrow: $('.partners-slider__arrow-prev'),
      nextArrow: $('.partners-slider__arrow-next'),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  $('.how-it-work-slider').slick({
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.how-it-work-slider__arrow-prev'),
    nextArrow: $('.how-it-work-slider__arrow-next'),
  });

  $('.tabs__arrow-prev').on('click', function () {
    let panelID = $('.tabs-panel.is-active').prev().attr('id');
    if (panelID) {
      $('#example-tabs').foundation('selectTab', panelID);
    } else {
      let panelIdFirst = $('.tabs-panel.is-active')
        .siblings()
        .last()
        .attr('id');
      $('#example-tabs').foundation('selectTab', panelIdFirst);
    }
    let activeTitleContent = $('.tabs-title.is-active').text();
    $('.tabs-first-title').text(activeTitleContent);
  });

  $('.tabs__arrow-next').on('click', function () {
    let panelID = $('.tabs-panel.is-active').next().attr('id');
    if (panelID) {
      $('#example-tabs').foundation('selectTab', panelID);
    } else {
      let panelIdFirst = $('.tabs-panel.is-active')
        .siblings()
        .first()
        .attr('id');
      $('#example-tabs').foundation('selectTab', panelIdFirst);
    }
    let activeTitleContent = $('.tabs-title.is-active').text();
    $('.tabs-first-title').text(activeTitleContent);
  });

  let content = $('.tabs').find('.tabs-title.is-active').text();
  $('.tabs-first-title').text(content);

  $('.tabs-first-title').on('click', function () {
    $(this).toggleClass('open');
    if ($(this).hasClass('open')) {
      $('.tabs').css('display', 'flex');
    } else {
      $('.tabs').css('display', 'none');
    }

    $('.tabs-title').on('click', function () {
      let title = $(this).text();
      $('.tabs-first-title').text(title);
      $('.tabs').css('display', 'none');
      $('.tabs-first-title').removeClass('open');
    });
  });

  // $('.tabs__arrow-next').on('click', function () {
  //   let panelID = $('.tabs-panel.is-active').next().attr('id');
  //   $('#example-tabs').foundation('selectTab', panelID);
  // });

  let catContent = $('.categories-list')
    .find('.post-category.is-active')
    .text();
  $('.cat-first-title').text(catContent);

  $('.cat-first-title').on('click', function () {
    $(this).toggleClass('open');
    if ($(this).hasClass('open')) {
      $('.categories-list').css('display', 'flex');
    } else {
      $('.categories-list').css('display', 'none');
    }

    $('.post-category').on('click', function () {
      let title = $(this).text();
      $('.cat-first-title').text(title);
      $('.categories-list').css('display', 'none');
      $('.cat-first-title').removeClass('open');
    });
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
